import React, { useMemo, useState } from 'react';
import HorizontalTwoActionFooter from '../Footer/HorizontalTwoActionFooter';
import { t } from 'src/i18n/config';
import { PAGE_NAME as LAST_PAGE } from './SelectPatientsPage';
import styled from 'styled-components';
import Loading from '../1TalkDesign/Loading';

const SelectContainer = styled.div`
  margin: 24px 16px 0 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 16px 0;
  z-index: 9999;
`;

const IFrameFullScreen = styled.iframe`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 81px);
  border: none;
  background-color: white;
  z-index: 9999;
`;

function PinMedHisBookingPage({
  patient,
  bindingPhoneNumber,
  onChangeStep,
  hisBookingIframeUrl,
}) {
  const [isLoadingIframe, setIsLoadingIframe] = useState(true);

  const handleBack = () => {
    onChangeStep(LAST_PAGE);
  };

  const userInfo = useMemo(() => {
    if (!patient) {
      return {};
    }
    return {
      name: patient.name || '',
      gender: patient.gender || 'M',
      birth: patient.birth || '',
      phone: bindingPhoneNumber,
      idType: patient.idType,
      idNum: patient.idNum,
      openFromLiff: true,
      utm_source: 'line',
      utm_medium: 'referral',
      utm_campaign: 'liff',
    };
  }, [bindingPhoneNumber, patient]);

  const params = new URLSearchParams(userInfo).toString();
  return (
    <>
      <SelectContainer>
        <IFrameFullScreen
          src={`${hisBookingIframeUrl}?${params}`}
          onLoad={() => setIsLoadingIframe(false)}
          style={{ display: isLoadingIframe ? 'none' : 'block' }}
        />
      </SelectContainer>
      {isLoadingIframe && (
        <LoadingWrapper>
          <Loading color="#006db1" />
        </LoadingWrapper>
      )}
      <HorizontalTwoActionFooter
        leftFunc={handleBack}
        leftText={t('reselectPatient')}
        leftEndIcon={<i className="ri-arrow-left-s-line"></i>}
      ></HorizontalTwoActionFooter>
    </>
  );
}

export default PinMedHisBookingPage;
export const PAGE_NAME = 'PinMedHisBookingPage';
export const prepareProps = async (lastState) => {
  return {
    ...lastState,
  };
};
